import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

import { gridConfigs } from "configs/appConfigs";

import ClinicusGrid from "common/ClinicusGrid/ClinicusGridV2";
import BackButton from "common/BackButton/BackButton";
import Title from "common/Title";

const SearchResults = () => {
	const gridRef = useRef(null);
	const location = useLocation();
	const { navItems, selectedItem } = useSelector(store => store.navSlice);

	// Extract query parameters from URL
	const queryParams = new URLSearchParams(location.search);
	const query = queryParams.get("search_query") || "";

	const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

	const formatters = {
		linkFormatter: (cell, row) => {
			const { sfid } = row;
			return (
				<Link
					to={`/homepage/total-patients-report/details/vitals/${sfid}`}
					className={`underline ${gridConfigs.colors.defaults.linkFormatterClassName}`}
				>
					{cell}
				</Link>
			);
		},
		dateFormatter: (cell, row) => {
			if (cell) {
				return moment(cell).tz(systemTz).format("Do MMM, YY hh:mm A");
			}
			return "-";
		},
		dobFormatter: (cell, row) => {
			return moment(cell).tz(systemTz).format("MM/DD/YYYY");
		},
	};

  	return (
		<div className="px-2">
			<header>
				<BackButton
					path={navItems[selectedItem]?.pathName || "/homepage"}
					text="Go Back"
					className="mt-3"
				/>
				<br/>
				<Title titleElement={`Search Results for "${query}"`}></Title>
			</header>
			<ClinicusGrid
				gridDataUrl={`/object/search?query=${encodeURIComponent(query)}`}
				formatters={formatters}
				ref={gridRef}
				sort={{ createddate: "desc" }}
				objectId={"patients"}
			/>
		</div>
	);
};

export default SearchResults;
