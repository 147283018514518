import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AUTH_STATUS } from "enums/authEnum";
import { axiosGet } from "utils/axiosAsync/async";
import { filterNavItems } from "./navSlice";

export const getUserDetails = createAsyncThunk('getUserDetails', async  (userReqParams, { dispatch }) => {
    const [err, resp] = await axiosGet("user/getDetails", userReqParams);

    if(err) {
        console.log(err);
        throw new Error(`Failed to fetch user details`);
    }

    const user = resp.data;
    dispatch(filterNavItems(user.permissions));
    return user;
})

const userSlice = createSlice({
    name: "user",
    initialState: {
        user: null,
        status: AUTH_STATUS.LOGGED_OUT,
        error: null
    },
    reducers: {
        updateUserInfo: (state, action) => {
            state.user = action.payload;
        },
        logoutUser: (state) => {
            state.user = null;
            state.status = AUTH_STATUS.LOGGED_OUT;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserDetails.pending, (state, action) => {
            state.status = AUTH_STATUS.LOGGING_IN;
        });

        builder.addCase(getUserDetails.fulfilled, (state, action) => {
            state.status = AUTH_STATUS.LOGGED_IN;
            state.user = action.payload;
        });

        builder.addCase(getUserDetails.rejected, (state, action) => {
            state.status = AUTH_STATUS.LOGGING_ERROR;
            state.error = action.error;
        });
    }
});
export const { updateUserInfo, logoutUser } = userSlice.actions;
export default userSlice.reducer;
