import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { List, Drawer } from "antd";

import { getNavItemIcon } from "../../utils";
import { updateNavItems } from "../../store/navSlice";
import { sidebarConfigs } from "../../configs/appConfigs";

const Sidebar = () => {
	const dispatch = useDispatch();
    const { navItems, selectedItem, isSidebarOpen, isSidebarCollapsed, isSidebarToggle } = useSelector(state => state.navSlice);

	const handleNavClick = (idx) => {
		dispatch(updateNavItems({ selectedItem: idx }));
	}

	const closeSidebar = () => {
		dispatch(updateNavItems({ isSidebarOpen: false }));
	}

	const collapseSidebar = (isSidebarToggle, isSidebarCollapsed) => {
		dispatch(updateNavItems({ isSidebarToggle, isSidebarCollapsed }));
	}

	const expandSidebar = () => {
		dispatch(updateNavItems({ isSidebarCollapsed: false }));
	}

	return (
		<Drawer
			placement="left"
			width={isSidebarCollapsed ? sidebarConfigs.collapsedWidth : sidebarConfigs.expandedWidth}
			closable={false}
			styles={{ body: { padding: sidebarConfigs.defaultPadding }, header: { padding: sidebarConfigs.defaultPadding } }}
			onClose={() => closeSidebar()}
			open={isSidebarOpen}
			onMouseEnter={() => isSidebarCollapsed && expandSidebar()}
			onMouseLeave={() => isSidebarToggle && collapseSidebar(true, true)}
			mask={false}
			className={sidebarConfigs.colors.defaults.bgClassName}
		>
			<List 
				size="large"
				dataSource={navItems}
				className="mt-20"
				renderItem={(item, idx) => (
					<List.Item className={`${selectedItem === idx ? `${sidebarConfigs.colors.defaults.activeNavClassName}`: ""} rounded-xl ${sidebarConfigs.colors.defaults.hoverNavClassName} p-0 my-1`}>
						<List.Item.Meta
							title={
								<Link key={item.configName} className={`flex gap-2 no-underline text-base ${selectedItem === idx ? "font-bold" : "font-normal"} py-2 ${sidebarConfigs.colors.defaults.textColor}`} to={item.pathName} onClick={() => handleNavClick(idx)}>
									<div className="pl-3.5">
										{getNavItemIcon(item.configName)}
									</div>
									<div className="whitespace-nowrap overflow-hidden">
										{isSidebarCollapsed ? "" : item.appName}
									</div>
								</Link>
							}
						/>
					</List.Item>
				)}
			/>
		</Drawer>
    );
}

export default Sidebar;