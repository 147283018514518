import React, { useEffect } from "react";
import { Collapse } from "reactstrap";
import { Bars3Icon, XMarkIcon, Bars3BottomLeftIcon, BellIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { get, map } from "lodash";

import { permissions, validatePermissions } from "permissionsList";
import { signOut } from "../../msalAuth";
import { getUserDetails } from "store/userSlice";

import { axiosPost } from "../../utils/axiosAsync/async";
import { updateNavItems } from "../../store/navSlice";
import { sidebarConfigs } from "../../configs/appConfigs";

import clinicusLogo from '../../assets/img/Logo.svg';

import ClinicusDropdown from "../../common/Form/ClinicusDropdown";
import SearchComponent from "./SearchComponent";

const Header = () => {
	const [openNav, setOpenNav] = React.useState(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const [attachments, setAttachments] = React.useState([]);
	const [options, setOptions] = React.useState([{ label: "Sign out", value: "sign_out" }]);

	const { isSidebarOpen, navItems } = useSelector(state => state.navSlice);
	const { user } = useSelector(state => state.userSlice);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		const handleResize = () => window.innerWidth >= 960 && setOpenNav(false);
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		handleProfileOptions();
	},[user.email]);

	const handleProfileOptions = () => {
		let ops = options;
		const opsValues = map(ops, "value") || [];
		const userPerms = get(user, 'permissions', []);
		const actualUserPerms = get(user, 'actualUser.permissions', []);
		const allPermissions = [ ...userPerms, ...actualUserPerms ];

		if(validatePermissions(allPermissions, Object.values(permissions.impersonate))) {
			if(!opsValues.includes("impersonate"))
				ops.push({ label: "Impersonate", value: "impersonate" });
		} else {
			ops = ops.filter(op => op.value !== "impersonate");
		}

		if(user.isImpersonatedUser) {
			if(!opsValues.includes("unmask_impersonate"))
				ops.push({ label: "Unmask Impersonate", value: "unmask_impersonate" });
		} else {
			ops = ops.filter(op => op.value !== "unmask_impersonate");
		}

		setOptions(ops);
	}

	const handleUnmaskImpersonate = () => {
		dispatch(getUserDetails(user.actualUser, false, ""));
	}

	const handleLogoutRedirect = async () => {
		const [err] = await signOut();

		if(err) {
			console.log(err);
		}
	};

	const handleSidebar = () => {
		dispatch(updateNavItems({ isSidebarOpen: !isSidebarOpen }));
	}

	const expandSidebar = () => {
		dispatch(updateNavItems({ isSidebarToggle: false, isSidebarCollapsed: false }));
	}

	const onClickProfileOption = (option) => {
		if(option.value === "sign_out") {
			handleLogoutRedirect();
		} else if(option.value === "impersonate") {
			navigate("/impersonate");
		} else if(option.value === "unmask_impersonate") {
			handleUnmaskImpersonate();
		}
	}

	const renderProfile = () => {
		const displayComponentClassName = "bg-[#03294E] text-nowrap text-white text-sm font-medium border border-solid rounded-3xl";
		const dropdownClassName = "flex w-14 xm:w-24 sm:w-full border-[#597795] border border-solid rounded-3xl"
		return (
			<ClinicusDropdown
				isOpen={isOpen}
				toggle={() => setIsOpen(!isOpen)}
				options={options}
				displayText={{ label: user?.name }}
				onClickOption={onClickProfileOption}
				displayComponentClassName={displayComponentClassName}
				dropdownClassName={dropdownClassName}
				size="md"
			/>
		);
	}


    const onChangeUploadFiles = (event) => {
        setAttachments(event.target.files);
    };

    const onClickSubmit = async () => {
        let formData = new FormData();

        // if (attachments.length > 0) {
        //     for (let index = 0; index < attachments.length; index++) {
        //         const file = attachments[index];
        //         formData.append('file', file);
		// 		console.log(file);
        //     }
        // }

		formData.append('file', attachments[0]);
        formData.append('createdBy', "sample_createdby_sfid");
        formData.append('updatedBy', "sample_updatedby_sfid");

		await axiosPost("/patient/bulkCreate", formData).then(d => console.log(d)).catch(e => console.log(e));
    }

  	return (
		<div className={`${sidebarConfigs.colors.defaults.bgClassName} flex sticky top-0 z-[1001]`}>
			<div className="pr-4 w-full">
				<div className="flex items-center justify-between">

					{/* Logo */}
					<div className="mr-4 py-1 cursor-pointer ml-1 flex items-center gap-2">
						<Link to={navItems?.[0]?.pathName} onClick={() => dispatch(updateNavItems({ selectedItem: 0 }))} className="flex items-center no-underline">
							<img src={clinicusLogo} alt="Clinicus Logo" className="p-1"/>
							<div className="text-white text-lg font-medium">
								CLINICUS
							</div>
						</Link>
				 		<Bars3BottomLeftIcon className="h-6 w-6 text-white" strokeWidth={2} onClick={handleSidebar} />
					</div>

					{/* Search */}
					<div className="hidden md:block">
						<SearchComponent />
						{/* <input
							type='file'
							name='patientFile'
							onChange={onChangeUploadFiles}
							required
							accept={".csv"}
						></input>
						<button onClick={onClickSubmit}>API</button> */}
					</div>

					{/* Utilities */}
					<div className="flex gap-8 items-center">

						<div className="flex gap-4">
							{/* Notifications */}
							{/* <div className="relative inline-flex w-fit">
								<div className="absolute bottom-auto left-auto right-0 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-[#E60000] px-2 py-1 text-center align-baseline text-xs font-bold leading-none text-white">
									9+
								</div>
								<BellIcon className="h-6 w-6 text-white" />
							</div> */}

							<div className="md:hidden cursor-pointer text-white" onClick={() => setOpenNav(!openNav)}>
								{openNav ? (
									<XMarkIcon className="h-6 w-6" strokeWidth={2} />
								) : (
									<Bars3Icon className="h-6 w-6" strokeWidth={2} />
								)}
							</div>
						</div>

						{renderProfile()}
				 	</div>
				</div>

				<Collapse isOpen={openNav} className="mb-3">
					<div className="flex gap-1 justify-end w-full">
						<SearchComponent />
					</div>
				</Collapse>
			</div>
		</div>
	);
};

export default Header;