import { intersection } from "lodash";

export const permissions = {
    homepage: {
        homepageRead: 'CLINICUS_HOMEPAGE_READ',
    },
    patients: {
        patientsRead: 'CLINICUS_PATIENTS_READ',
        patientsEdit: 'CLINICUS_PATIENTS_EDIT'
    },
    impersonate: {
        impersonateUser: 'CLINICUS_IMPERSONATE'
    },
    tcm: {
        tocRead: 'CLINICUS_TCM_DASHBOARD',
        providerDashboard: 'CLINICUS_TCM_DASHBOARD_PROVIDER',
        nurseDashboard: 'CLINICUS_TCM_DASHBOARD_NURSE'
    }
};

export const validatePermissions = (userPermissions, permissions) => {
    if (userPermissions && permissions) {
        return intersection(userPermissions, permissions).length > 0;
    }
    return false;
}
